.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  min-height: 50px;
  background-color: #212d3b;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext.left {
  top: -5px;
  right: 120%;
}

.tooltip .tooltiptext.bottom {
  top: 120%;
  left: 50%;
  margin-left: -100px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext.bottom::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #212d3b transparent;
}

.tooltip .tooltiptext.left::after {
  content: " ";
  position: absolute;
  top: 18%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #212d3b;
}
