.custom-toast-error {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: left;
  color: #fff !important;
  background: #cf0000 !important;
  margin-top: 10px;
}

.Toastify__close-button--light {
  color: #fff !important;
  opacity: 0;
}

.Toastify__toast-container--top-right {
  top: 5em;
}
.Toastify__close-button > svg {
  fill: #fff;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 261px;
    left: auto;
  }
}
