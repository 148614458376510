.checkoutInput {
  &.hasError {
    @apply border-red-500;
  }
}

.checkoutSelect {
  @apply py-3 px-4 text-sm;
}

.checkoutLabel {
  @apply text-xs uppercase mt-3 text-neutral-500 font-bold;
}

.checkoutError {
  @apply text-red-500 text-xs mt-0.5;
}

.checkoutWarning {
  @apply text-yellow-500 text-xs mt-0.5;
}
