@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/poppins/Poppins-Bold.otf') format('OpenType');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/poppins/Poppins-Regular.otf') format('OpenType');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MedievalSharp';
    src: url('../public/fonts/MedievalSharp-Regular.ttf') format('OpenType');
    font-weight: normal;
    font-style: normal;
  }
  body {
    margin: 0;
    font-family: 'Libre Franklin', sans-serif;
  }
  h1 {
    @apply text-2xl mb-6;
  }
  h2 {
    @apply text-xl mb-4;
  }
  h3 {
    @apply text-lg mb-2;
  }
  a {
    @apply text-blue-600 hover:text-blue-500 underline;
  }
  p {
    @apply mb-2;
  }
}

body {
  @apply text-neutral-700 text-sm;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button[disabled] {
  cursor: not-allowed;
}

.Toastify__toast--success {
  @apply bg-atlantis !important;
}

.Toastify__toast--warning {
  @apply bg-zest !important;
}

.Toastify__toast-icon {
  @apply self-start mt-0.5;
}

.Toastify__toast--success .Toastify__close-button,
.Toastify__toast--warning .Toastify__close-button {
  @apply text-white opacity-100 !important;
}
