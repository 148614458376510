.card {
  @apply relative flex flex-col bg-white rounded;
}

.card:focus-within {
  box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.25);
}

.name {
  @apply no-underline text-neutral-700 hover:text-neutral-700 focus:outline-none;
}

.name::after {
  content: '';
  @apply absolute inset-0;
}
