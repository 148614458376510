.custom-toast-success {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-align: left;
  background: #038600 !important;
  color: #fff !important;
  text-transform: uppercase;
  margin-top: 10px;
}
.Toastify__close-button--light {
  color: #fff !important;
  opacity: 1;
}

.Toastify__toast-container--top-right {
  top: 5em;
}


@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 227;
    left: auto;
  }
}
