.gridAndFilter {
  @apply relative flex flex-col flex-grow transition-all px-2;

  &.filtersHidden {
    @apply ml-0;
  }

  &.filtersVisible {
    @apply sm:ml-80;
  }
}

.filterPanelContainer {
  @apply absolute z-10 top-0 right-0 bottom-0 left-0 bg-white transition-transform;
  @apply sm:z-auto sm:-left-80 sm:right-auto sm:w-80 sm:translate-x-0;

  &.filtersHidden {
    @apply -translate-x-full;
  }

  &.filtersVisible {
    @apply translate-x-0;
  }
}

.resultsGrid {
  @apply flex-grow grid gap-8 grid-cols-1 transition-all;

  &.filtersHidden {
    @apply sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6;
  }

  &.filtersVisible {
    @apply lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6;
  }
}
