.headerLogoLink {
  .logo {
    @apply transform rotate-180 transition-all duration-300;
  }
  &:hover {
    .logo {
      @apply rotate-0;
    }
  }
}
