.custom-toast-pending {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-align: left;
  background: #bfbfbf !important;
  color: black !important;
  text-transform: uppercase;
}

.Toastify__close-button--light {
  color: #000 !important;
  opacity: 1;
}

.Toastify__toast-container--top-right {
  top: 5em;
}
