.nftDescription ol,
ul {
  padding: revert;
}

.nftDescription ol {
  list-style: decimal;
}

.nftDescription ul {
  list-style: disc;
}

.nftCartItems {
  height: 500px;
}

.nftCartItem {
  max-width: 100%;
  display: grid;
  grid-template-columns: minmax(130px, 20%) auto minmax(130px, 20%);
}
