.loaderContainer {
  position: relative;
}

.load {
  position: absolute;
  z-index: 3;
}

.load:before {
  border-color: transparent #ffffff73 transparent transparent;
  border-radius: 66px 60px 60px 60px;
  border-right: 0px solid #ffffff78;
  border-style: solid;
  border-width: 26px;
  content: "";
  left: -26px;
  top: -26px;
  position: absolute;
  animation: rotate .95s infinite linear;
}

@keyframes rotate { 
  from {
    transform: rotate(0deg);
  }
  to { 
    transform: rotate(360deg);
  }
}
