.wrapper {
  position: relative;
}

.tooltip-box {
  z-index: 10;
  position: absolute;
  background: #212d3b;
  border-radius: 4px;
  display: none;
  top: 34px;
  color: #ffffff;
  padding: 15px;
  font-weight: 500;
  font-size: 13px;
  align-items: center;
  justify-content: center;
}

.tooltip-box.left {
  top: -10px;    
  left: -140px;
}

.tooltip-box.visible {
  display: flex;
  width: 15rem;
}

.tooltip-arrow {
  position: absolute;
  top: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #212d3b transparent;
}

.tooltip-arrow.left {
  right: -10px;
  top: 18px;    
  -webkit-transform: rotate(90deg);   
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
