.md h1,
.md h2,
.md h3,
.md h4,
.md h5,
.md h6 {
  @apply font-bold mt-8 mb-2;
}

.md h1 {
  @apply text-5xl;
}

.md h2 {
  @apply text-4xl;
}

.md h3 {
  @apply text-3xl;
}

.md h4 {
  @apply text-2xl;
}

.md h5 {
  @apply text-xl;
}

.md h6 {
  @apply text-lg;
}

.md p,
.md ul,
.md li {
  @apply mb-4;
}

.md ul {
  @apply ml-6 list-outside list-disc;
}

.large p,
.large ul,
.large li {
  @apply text-lg;
}
